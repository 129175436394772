@import '../../../../scss/theme-bootstrap';

.image-recommender-overlay {
  position: fixed;
  background: $color-gold;
  width: 100vw;
  height: 100%;
  #{$ldirection}: 0;
  top: 0;
  z-index: 100;
}

.image-recommender-overlay__loading {
  display: flex;
  justify-content: center;
  position: relative;
  top: 20%;
  text-align: center;
}

.image-recommender-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.image-recommender-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-recommender-container > div {
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image-recommender-spinner {
  @include spinner(36px, 36px);
}
